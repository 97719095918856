<template>
  <div class="content-template">
    <mds-button
      v-if="back"
      class="content-template__back"
      icon="angle-left"
      variation="flat"
      on-dark
      @click="$emit('back', $event)"
    >
      Back
    </mds-button>
    <heading
      v-if="heading"
      :level="1"
      class="content-template__heading"
    >
      {{ heading }}
    </heading>
    <div
      :class="{ 'content-template__content-container--contained': contained }"
      class="content-template__content-container"
    >
      <div
        :class="{ 'content-template__content--no-padding': noPadding }"
        class="content-template__content"
      >
        <heading
          v-if="title"
          :level="heading ? 2 : 1"
          class="content-template__title"
        >
          {{ title }}
        </heading>
        <mds-tabs
          v-if="navigation"
          :content="navigation"
          class="content-template__navigation"
        />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '../Heading/Heading'
import { MdsButton } from '@mds/button'
import MdsTabs from '@mds/tabs'

export default {
  components: {
    Heading,
    MdsButton,
    MdsTabs,
  },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    contained: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    navigation: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.content-template {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 70px 1fr 70px;
  gap: $mds-space-4-x;
}

.content-template__back {
  justify-self: start;
  align-self: center;
}

.content-template__heading {
  grid-column: 2 / span 1;
  justify-self: center;
  @include mds-level-2-heading();
  color: $mds-text-color-primary-on-dark;
  margin: 0;
}

.content-template__content-container {
  grid-column: 1 / -1;

  &--contained {
    padding: 90px $mds-space-2-x;
    border-radius: $mds-border-radius-panel;
    background-color: $mds-background-color-light-gray;
    box-shadow: var(--shadow--panel);

    .content-template__content {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

.content-template__content {
  padding: var(--space--10-x);
  background-color: $mds-background-color-white;
  border-radius: $mds-border-radius-panel;
  box-shadow: var(--shadow--panel);

  &--no-padding {
    padding: 0;
  }
}

.content-template__title {
  @include mds-level-2-heading($bold: true);
  margin: 0 0 $mds-space-4-x 0;
}

.content-template__navigation {
  margin-bottom: $mds-space-5-x;
}
</style>
